import './App.css';
import NailList from "./ImageConfig";
import LineDrawing from "./LineDrawing";
import React from "react";

function App() {
    const calculateCoordinates = () => {
        let coordinates = [];
        let x = 0;
        let y = 0;
        let step = 4;

        for (let n = 0; n < 360; n++) {
            coordinates = [...coordinates, {x: x, y: -y}];
            if (n < 90) x = x + step;
            if (90 <= n && n < 180) y = y - step;
            if (180 <= n && n < 270) x = x - step;
            if (270 <= n && n < 360) y = y + step;
        }
        return coordinates;
    }
    const nailList = NailList();
    const coordinates = CalculateNailCoordinates(nailList, calculateCoordinates());

    return (
        <div className="App">
            <div className="body">
                <div className="hello">
                    <span>Сәлем Мерей</span>
                    <span>Туған күнің құтты болсын!</span>
                </div>
                <div className="congratulation">
                    <div className="text" style={{textAlign: "center"}}>
                        <span>Саған әлемдегі бар жақсылықты тілегім келеді.</span>
                    </div>
                    <div className="cake-logo"/>
                    <div className="text">
                        <span>Сен - мен өмірімде көрген ең ерекше адамсың</span>
                    </div>
                    <div className="photo3"/>
                    <div className="text">
                        <span>Мен саған осы сұрақты қойғаныма өте қуаныштымын. Себебі соның арқасында біз таныстық.</span>
                        <span className="details">13-04-2024</span>
                    </div>
                    <div className="text">
                        <span>Сол күні мен таныған Мерей болып қала бер. Қалаған жетістіктеріңе жете бер, сенің қолыңнан барлығы келеді деп сенемін.</span>
                    </div>
                    <div className="photo2"/>
                    <div className="text">
                        <span>Негізі общий суреттермен калаш қосқым келген, бірақ общий сурет жоқ😅. Сол себепті басқа зат жасадым</span>
                    </div>
                    <LineDrawing coordinates={coordinates}/>
                    <div className="text">
                        <span>Тәулік уақыты мен ауа-райына қарамастан, біз күн нұрын көре беру үшін күлімсіріп жүре бер!</span>
                    </div>
                    <div className="text" style={{fontSize: 25, color: "blue", marginTop: 80}}>
                        <span>Осындай творчествомен айналысуға қызықтыруың үшін рахмет, тағы да мерекең құтты болсын!</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function CalculateNailCoordinates(nailList, coordinates) {
    let nailCoordinates = [];
    nailList.forEach(function (nail) {
        nailCoordinates = [...nailCoordinates, coordinates[nail - 1]];
    })
    return nailCoordinates;
}

export default App;
