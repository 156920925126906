import React, { useEffect, useState } from 'react';
import { Layer, Stage, Line } from "react-konva";

const LineDrawing = ({ coordinates }) => {
    const [visibleLines, setVisibleLines] = useState([]);
    const timeout = 100;
    const step = 10

    useEffect(() => {
        draw();
    }, [coordinates]);

    const draw = () => {
        setVisibleLines(coordinates.slice(0, step));
        for (let i = step; i < coordinates.length; i = i + step) {
            setTimeout(() => {
                setVisibleLines((prevVisibleLines) => prevVisibleLines.concat(coordinates.slice(i, i + step)));
            }, timeout * (i / step));
        }
    };

    const reloadDraw = () => {
        setVisibleLines([]);
        draw();
    };

    return (
        <div className="drawing">
            <div className="button" onClick={reloadDraw}>
                <span>Обновить</span>
                <button className="reload-button" />
            </div>
            <div style={{ backgroundColor: "white" }}>
                {3950 < visibleLines.length && visibleLines.length < 3995 ?
                    <div className="photo1-black"/> :
                    visibleLines.length > 3995 ? (
                        <div className="photo1"/>
                    ) : (
                        <Stage width={360} height={360}>
                            <Layer>
                                {visibleLines.map((point1, index) => {
                                    if (index !== 0) {
                                        let point2 = visibleLines[index - 1];
                                        return (
                                            <Line
                                                points={[point1.x, point1.y, point2.x, point2.y]}
                                                stroke="black"
                                                strokeWidth={0.1} />
                                        );
                                    }
                                })}
                            </Layer>
                        </Stage>
                    )
                }
            </div>
        </div>
    );
};

export default LineDrawing;
